import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import ScrollToTop from './components/ScrollToTop';

import generateRoutesFromPages from './pages/generateRoutesFromPages';

const Routes = () => (
  <Router>
    <ScrollToTop>
      <Switch>
        {generateRoutesFromPages()}
        <Route
          render={() => (
            <PageContainer title="404 - Page Not Found">
              Page not found ☹
            </PageContainer>
          )}
        />
      </Switch>
    </ScrollToTop>
  </Router>
);

export default Routes;
