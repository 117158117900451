import { getCell } from '../gameLogic';

export const countAxis = (position, player, start, axis) => {
  const forwardsCount = countFrom(position, player, start, axis);

  const backwardsCount = countFrom(position, player, start, axis.map(d => -d));

  return forwardsCount + backwardsCount;
};

const countFrom = (position, player, { row, col }, [rowDelta, colDelta]) => {
  let r, c, i;

  for (i = 1; i < 4; i++) {
    r = row + i * rowDelta;
    c = col + i * colDelta;

    if (
      (r >= 7 && r < 0 && c >= 6 && c < 0) ||
      getCell(position, r, c) !== player
    ) {
      break;
    }
  }
  return i - 1;
};
