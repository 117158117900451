import './styles.css';

import React from 'react';

const IconLink = ({ children, url }) => (
  <a className="IconLink" href={url} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export default IconLink;
