import './styles.scss';

import React from 'react';

const CircularImage = ({ className, src }) => (
  <div className={className}>
    <div className="CircularImage__container">
      <img className="CircularImage__image" src={src} alt="portrait" />
    </div>
  </div>
);

export default CircularImage;
