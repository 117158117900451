import './styles.scss';

import React from 'react';

import cx from 'classnames';

import ResponsiveGrid from 'components/ResponsiveGrid';
import { ReactComponent as Knight } from './images/knight.svg';
import { ReactComponent as XMark } from './images/x-mark.svg';

const validateValue = val => !isNaN(val) && val >= 0;

const processValue = val => (val === '' ? 0 : parseInt(val));

export default class KnightsTour extends React.Component {
  state = {
    knightPosition: '1,0',
    knightVisited: {},
    numRows: 8,
    numCols: 8,
    knightSpeed: 100,
  };

  render() {
    const { knightPosition, knightVisited, numRows, numCols, knightSpeed } =
      this.state;

    return (
      <div className="KnightsTour">
        <div>
          <div>
            Knight movement interval (ms):{' '}
            <input
              type="text"
              value={knightSpeed || ''}
              onChange={this.onChangeKnightSpeed}
            />
          </div>
          <div>
            Number of Rows:{' '}
            <input
              type="text"
              value={numRows || ''}
              onChange={this.onChangeBoardRows}
            />
          </div>
          <div>
            Number of Columns:{' '}
            <input
              type="text"
              value={numCols || ''}
              onChange={this.onChangeBoardColumns}
            />
          </div>
          <button onClick={this.startTour}>Start Tour!</button>
          <br />
          <br />
        </div>

        <ResponsiveGrid
          numRows={numRows}
          numCols={numCols}
          cellRenderer={({ row, col }) => {
            const rowCol = `${row},${col}`;
            const isLightSquare = (row + col) % 2 === 0;
            const isVisitedSquare = knightVisited[rowCol];
            const isKnightSquare = knightPosition === rowCol;

            return (
              <div
                key={rowCol}
                onClick={() => this.onClickCell(rowCol)}
                className={cx(
                  'KnightsTour__square',
                  { 'KnightsTour__square--light': isLightSquare },
                  { 'KnightsTour__square--dark': !isLightSquare },
                  { 'KnightsTour__square--visited': isVisitedSquare },
                  { 'KnightsTour__square--knight': isKnightSquare }
                )}
              >
                {isVisitedSquare && <XMark />}
                {isKnightSquare && <Knight />}
              </div>
            );
          }}
        />
      </div>
    );
  }

  startTour = () => {
    const { knightPosition, knightSpeed, numRows, numCols } = this.state;

    if (numRows > 0 && numCols > 0) {
      this.endTour();
      this.clearOldTour();

      !knightPosition && this.setState({ knightPosition: '0,0' });

      this.activeTour = setInterval(this.moveKnight, knightSpeed);
    }
  };

  clearOldTour = () => this.setState({ knightVisited: {} });

  endTour = () => {
    clearInterval(this.activeTour);
    this.activeTour = null;
  };

  moveKnight = () => {
    const { knightPosition, knightVisited } = this.state;
    const [row, col] = knightPosition.split(',').map(num => parseInt(num));

    const nextMove = this.getBestMove(row, col);

    if (nextMove) {
      this.setState({
        knightPosition: nextMove.join(','),
        knightVisited: { ...knightVisited, [knightPosition]: true },
      });
    } else {
      this.endTour();
    }
  };

  /* 
    Returns the move with the FEWEST onward moves. This is a heuristic called
    Warnsdorff's Rule.
  */
  getBestMove = (row, col) =>
    this.possibleMoves(row, col)
      .map(move => ({
        move,
        numOnwardMoves: this.possibleMoves(move[0], move[1]).length,
      }))
      .reduce(
        (bestSoFar, move) =>
          move.numOnwardMoves < bestSoFar.numOnwardMoves ? move : bestSoFar,
        { numOnwardMoves: Number.POSITIVE_INFINITY }
      ).move;

  possibleMoves = (row, col) =>
    [
      [row - 2, col + 1],
      [row - 1, col + 2],
      [row + 1, col + 2],
      [row + 2, col + 1],
      [row - 2, col - 1],
      [row - 1, col - 2],
      [row + 1, col - 2],
      [row + 2, col - 1],
    ].filter(this.moveIsLegal);

  moveIsLegal = ([row, col]) =>
    row >= 0 &&
    row < this.state.numRows &&
    col >= 0 &&
    col < this.state.numCols &&
    !this.state.knightVisited[`${row},${col}`];

  onClickCell = knightPosition => {
    this.onChangeGameState();
    this.setState({ knightPosition });
  };

  onChangeGameState = () => {
    this.endTour();
    this.clearOldTour();
  };

  updateGameState = (key, value) =>
    validateValue(value) &&
    this.setState({ [key]: processValue(value) }, this.onChangeGameState);

  onChangeKnightSpeed = ({ target: { value } }) =>
    this.updateGameState('knightSpeed', value);

  onChangeBoardRows = ({ target: { value } }) =>
    this.updateGameState('numRows', value);

  onChangeBoardColumns = ({ target: { value } }) =>
    this.updateGameState('numCols', value);
}
