import './styles.scss';

import React from 'react';

import DateCreated from 'components/DateCreated';
import PageWidthContainer from 'components/PageWidthContainer';

const TitleArea = ({ title, dateCreated }) => (
  <PageWidthContainer className="TitleArea">
    <h1 className="TitleArea__title">{title}</h1>
    {dateCreated && <DateCreated dateCreated={dateCreated} />}
  </PageWidthContainer>
);

export default TitleArea;
