import React from 'react';

import LiveDateDifference from './components/LiveDateDifference';

const msToMonths = ms => ms / 2628000000;
const msToYears = ms => ms / 31557600000;
const isLessThan1Year = ms => msToYears(ms) < 1;
const numMonthsOrYears = ms =>
  (isLessThan1Year(ms) ? msToMonths(ms) : msToYears(ms)).toFixed(9);
const units = ms => (isLessThan1Year(ms) ? 'months' : 'years');

const FormattedLiveDateDifference = ({ date }) => (
  <LiveDateDifference date={date}>
    {ms => (
      <>
        <code>{numMonthsOrYears(ms)}</code> {units(ms)}
      </>
    )}
  </LiveDateDifference>
);

export default FormattedLiveDateDifference;
