import './styles.css';

import React from 'react';

import cx from 'classnames';

const PageWidthContainer = ({ className, children }) => (
  <div className={cx('PageWidthContainer', className)}>
    <div className="PageWidthContainer__inner">{children}</div>
  </div>
);

export default PageWidthContainer;
