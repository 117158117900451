import './styles.scss';

import React from 'react';

import cx from 'classnames';
import clonedeep from 'lodash.clonedeep';

import ResponsiveGrid from 'components/ResponsiveGrid';

import { P1, P2, WIN, DRAW, UNFINISHED } from './utils/constants';
import {
  applyMoveImmutable,
  isLegalMove,
  getCell,
  getDefaultPosition,
  getGameResult,
  getLastPlayer,
  getNextPlayer,
  stringifyCoords,
  getLegalMoves,
  isTerminal,
} from './utils/gameLogic';
import chooseMove, {
  ALPHA,
  BETA,
  getMoveValue,
  WIN_VALUE,
} from './utils/chooseMove';

const initialState = {
  position: getDefaultPosition(),
  hoverCol: undefined,
  aiThinking: false,
  aiDisabled: false,
  moveValues: {},
  aiDepth: 5,
};

const getMoveValues = (position, depth) => {
  const rv = {};
  if (isTerminal(position)) {
    return rv;
  }
  for (const move of getLegalMoves(position)) {
    rv[move] = getMoveValue(position, move, depth, ALPHA, BETA, true);
    if (rv[move] > WIN_VALUE * 0.9) {
      rv[move] = `${P2} wins!`;
    } else if (-rv[move] > WIN_VALUE * 0.9) {
      rv[move] = `${P1} wins!`;
    }
  }
  return rv;
};

class ConnectFour extends React.Component {
  state = initialState;

  render() {
    const { position, hoverCol, aiThinking, moveValues, aiDepth } = this.state;

    const lastPlayer = getLastPlayer(position);
    const nextPlayer = getNextPlayer(position);
    const gameResult = getGameResult(position);
    const gameOver = gameResult !== UNFINISHED;

    return (
      <div className="ConnectFour">
        <div>
          <div>
            AI is looking{' '}
            <button
              onClick={() =>
                aiDepth > 0 && this.setState({ aiDepth: aiDepth - 1 })
              }
            >
              -
            </button>
            {aiDepth}
            <button
              onClick={() =>
                aiDepth < 8 && this.setState({ aiDepth: aiDepth + 1 })
              }
            >
              +
            </button>{' '}
            moves ahead
          </div>
          <button onClick={this.resetGame}>Reset Game</button>
          <br />
          <br />
        </div>

        <div
          className={cx('ConnectFour__board-container', {
            'ConnectFour__board-container--not-legal': gameOver || aiThinking,
          })}
          onMouseLeave={() => this.setHoverCol()}
        >
          <ResponsiveGrid
            numRows={8}
            numCols={7}
            overlayRenderer={
              (gameOver || aiThinking) &&
              (() => (
                <div
                  className={cx('ConnectFour__overlay', {
                    'ConnectFour__overlay--hidden': !gameOver && !aiThinking,
                  })}
                >
                  {gameResult === UNFINISHED ? (
                    <div className="ConnectFour__loader" />
                  ) : (
                    <div>
                      <div>
                        {gameResult === WIN && `${lastPlayer} Wins!`}
                        {gameResult === DRAW && "It's a Draw ‾\\_(ツ)_/‾"}
                      </div>
                      <button onClick={this.resetGame}>Reset Game</button>
                    </div>
                  )}
                </div>
              ))
            }
            cellRenderer={({ row, col }) => {
              const cellValue = getCell(position, 6 - row, col);
              const isSelectionArea = row === 0;
              const isLegal =
                !gameOver &&
                !aiThinking &&
                hoverCol === col &&
                isLegalMove(position, col);

              return (
                <div
                  key={stringifyCoords(row, col)}
                  className={cx(
                    'ConnectFour__square',
                    { 'ConnectFour__selection-area': isSelectionArea },
                    { 'ConnectFour__board-area': !isSelectionArea },
                    {
                      'ConnectFour__square--column-hovered-legal':
                        isLegal && hoverCol === col,
                    },
                    {
                      'ConnectFour__square--column-hovered-not-legal':
                        !isLegal && hoverCol === col,
                    }
                  )}
                  onMouseMove={() => !aiThinking && this.setHoverCol(col)}
                  onClick={() => isLegal && this.humanMove(col)}
                >
                  {row === 7 ? (
                    <div className="ConnectFour__move-value">
                      {moveValues[col]}
                    </div>
                  ) : (
                    <div
                      className={cx(
                        'ConnectFour__circle',
                        { 'ConnectFour__circle--empty': !cellValue },
                        {
                          'ConnectFour__circle--p1': isSelectionArea
                            ? isLegal && hoverCol === col && nextPlayer === P1
                            : cellValue === P1,
                        },
                        {
                          'ConnectFour__circle--p2': isSelectionArea
                            ? isLegal && hoverCol === col && nextPlayer === P2
                            : cellValue === P2,
                        },
                        {
                          'ConnectFour__circle--floating-coin':
                            isSelectionArea && hoverCol === col,
                        }
                      )}
                    />
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }

  setHoverCol = hoverCol =>
    hoverCol !== this.state.hoverCol && this.setState({ hoverCol });

  humanMove = col => {
    this.setState(
      { position: applyMoveImmutable(this.state.position, col) },
      this.aiMove
    );
  };

  aiMove = () => {
    const { aiDisabled, aiDepth, position } = this.state;

    if (aiDisabled || getGameResult(position) !== UNFINISHED) {
      return;
    }

    this.setState({ aiThinking: true, hoverCol: undefined });

    setTimeout(async () => {
      const newPosition = applyMoveImmutable(
        position,
        await chooseMove(position, aiDepth)
      );

      this.setState({
        position: newPosition,
        aiThinking: false,
        moveValues: getMoveValues(clonedeep(newPosition), aiDepth),
      });
    });
  };

  resetGame = () =>
    this.setState({
      ...initialState,
      aiDepth: this.state.aiDepth,
    });
}

export default ConnectFour;
