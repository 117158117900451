import './styles.scss';

import React from 'react';

import PageWidthContainer from 'components/PageWidthContainer';

const PageContent = ({ children }) => (
  <PageWidthContainer className="PageContent">{children}</PageWidthContainer>
);

export default PageContent;
