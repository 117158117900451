import './styles.css';

import React from 'react';

const DateCreated = ({ dateCreated }) =>
  dateCreated && (
    <div className="DateCreated__label">
      {dateCreated.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}
    </div>
  );

export default DateCreated;
