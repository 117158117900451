import './styles.css';

import React from 'react';

import FormattedLiveDateDifference from './components/FormattedLiveDateDifference';

const AboutBlurb = () => (
  <div className="AboutBlurb">
    <h1>Hi, I'm Ollie.</h1>
    <ul>
      <li>
        I am{' '}
        <FormattedLiveDateDifference date="1995-01-06T15:00:00.000+10:00" />{' '}
        old.
      </li>
      <li>
        I live in{' '}
        <a href="https://en.wikipedia.org/wiki/New_York_City">New York City</a>.
      </li>
      <li>
        I graduated with a degree in Computer Science at{' '}
        <a href="http://www.berkeley.edu/">UC Berkeley</a>{' '}
        <FormattedLiveDateDifference date="2017-05-17T15:00:00.000-08:00" />{' '}
        ago.
      </li>
      {Date.now() > new Date('2022-03-07T09:00:00.000-08:00').getTime() && (
        <li>
          These days, I work as a Senior Software Engineer at{' '}
          <a href="https://www.getcruise.com/technology">Cruise</a>.
        </li>
      )}
    </ul>
  </div>
);

export default AboutBlurb;
