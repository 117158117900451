import React from 'react';

import ArticlesList from './components/ArticlesList';

import About from './components/About';
import KnightsTour from './components/KnightsTour';
import ConnectFour from './components/ConnectFour';
import StreamViewer from './components/StreamViewer';
import Calendar from './components/Calendar';
import Wifi from './components/Wifi';
import PasswordGenerator from './components/PasswordGenerator';
import WordOllie from './components/WordOllie';

import legacyPages from './legacyPages.json';

const ALLOWED_TEMPLATES = ['simple', 'header-and-footer'];

const pages = [
  {
    URI: 'password-generator',
    title: 'Password generator',
    blogBlurb:
      'Self-hosted random password generator for the times when the "suggest strong password" option isn\'t available.',
    dateCreated: new Date('2024-07-21T00:00Z'),
    showInArticlesList: true,
    component: PasswordGenerator,
  },
  {
    URI: 'word-ollie',
    title: 'Wordle Clone',
    blogBlurb:
      'Just a fun personal project to see if I could clone the popular game, Wordle.',
    dateCreated: new Date('2022-02-16T22:58:45.042Z'),
    hideTitleBar: true,
    showInArticlesList: true,
    component: WordOllie,
  },
  {
    URI: 'free-wifi',
    title: 'Wifi 🙂',
    showInArticlesList: false,
    component: Wifi,
  },
  {
    URI: 'calendar',
    title: 'Calendar',
    showInArticlesList: false,
    component: Calendar,
  },
  {
    URI: 'stream-viewer',
    title: 'Private stream viewer',
    dateCreated: new Date('2020-07-05T22:58:45.042Z'),
    blogBlurb:
      'I used this during the 2020 quarantine to share private livestreams with friends and family',
    showInArticlesList: false,
    component: StreamViewer,
  },
  {
    URI: 'connect-four',
    title: 'Connect Four',
    dateCreated: new Date('2019-05-27T22:58:45.042Z'),
    blogBlurb:
      "A Connect Four game with a minimax AI. It's not perfect but it should give you a run for your money.",
    showInArticlesList: true,
    component: ConnectFour,
  },
  {
    URI: 'knights-tour',
    title: "Knight's Tour",
    dateCreated: new Date('2019-05-18T22:58:45.042Z'),
    blogBlurb: "A fun interactive solution to the Knight's tour problem.",
    showInArticlesList: true,
    component: KnightsTour,
  },
  {
    URI: 'about',
    title: 'About',
    showInArticlesList: false,
    component: About,
  },
  {
    URI: '',
    title: 'Posts',
    showInArticlesList: false,
    component: ArticlesList,
  },
  ...legacyPages
    .filter(({ template }) => ALLOWED_TEMPLATES.includes(template))
    .map(({ URI, title, content, dateCreated, blogBlurb }) => ({
      URI,
      title,
      dateCreated: new Date(dateCreated),
      blogBlurb,
      showInArticlesList: true,
      component: () => <div dangerouslySetInnerHTML={{ __html: content }} />,
    })),
];

pages.sort((a, b) => b.dateCreated - a.dateCreated);

export default pages;
