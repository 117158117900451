import './styles.css';

import React from 'react';

import path from 'path';

import IconLink from './components/IconLink';

import { ReactComponent as GithubIcon } from './images/github.svg';
import { ReactComponent as LinkedInIcon } from './images/linkedin.svg';
import { ReactComponent as ResumeIcon } from './images/document.svg';

const AboutIcons = () => (
  <div className="About__icons">
    <IconLink url="https://www.linkedin.com/in/ollie-odonnell/">
      <LinkedInIcon />
      LinkedIn
    </IconLink>
    <IconLink url="https://github.com/ollie-o">
      <GithubIcon />
      GitHub
    </IconLink>
    <IconLink url={path.join(process.env.PUBLIC_URL, '/ollie-odonnell.pdf')}>
      <ResumeIcon />
      Resume
    </IconLink>
  </div>
);

export default AboutIcons;
