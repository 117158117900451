import './styles.css';

import React from 'react';

import { Link } from 'react-router-dom';

import pages from '../../pages';
import DateCreated from 'components/DateCreated';

const ArticlesList = () => (
  <div className="ArticlesList">
    {pages
      .filter(({ showInArticlesList }) => showInArticlesList)
      .filter(({ blogBlurb }) => blogBlurb)
      .map(({ URI: uri, title, blogBlurb, dateCreated }) => (
        <section className="ArticlesList__article" key={uri}>
          <h1 className="ArticlesList__title">
            <Link to={uri}>{title} </Link>
          </h1>

          <DateCreated dateCreated={dateCreated} />
          <div className="ArticlesList__blurb">{blogBlurb}</div>
        </section>
      ))}
  </div>
);

export default ArticlesList;
