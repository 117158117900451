import React from 'react';

const Wifi = () => (
  <>
    <p>
      Howdy neighbor{' '}
      <span role="img" aria-label="waving hand emoji">
        👋
      </span>
    </p>
    <p>
      If you're seeing this, then you probably saw a Wifi network named{' '}
      <code>ollie-o.com/free-wifi</code> and got curious what it's about.
    </p>
    <p>
      I'm sharing my guest Wifi network with my neighbors so you can use it as a
      temporary fix when you first move in, or when your ISP has an outage.
    </p>
    <p>
      I hope this helps! If you would like to pay it forward, you can enable the
      guest network on your router, or donate to my favorite charity, the{' '}
      <a href="https://www.givewell.org/charities/top-charities">
        Against Malaria Foundation
      </a>
      .
    </p>
    <h1>SSID</h1>
    <pre>ollie-o.com/free-wifi</pre>
    <h1>Password</h1>
    <pre>correcthorsebatterystaple</pre>
  </>
);

export default Wifi;
