import React from 'react';

import VideoPlayer from './components/VideoPlayer';

import { withRouter } from 'react-router-dom';
import qs from 'query-string';

const StreamViewer = ({ location, history }) => {
  const { url = '' } = qs.parse(location.search);

  const videoProps = {
    autoplay: true,
    controls: true,
    width: '100%',
    height: '100%',
    aspectRatio: '16:9',
    fluid: true,
    sources: [{ src: url }],
  };

  return (
    <div>
      <div>
        <div>Stream URL:</div>
        <input
          value={url}
          onChange={(evt) =>
            history.push({ search: qs.stringify({ url: evt.target.value }) })
          }
        />
        <hr />
      </div>
      {url && <VideoPlayer {...videoProps} />}
    </div>
  );
};

export default withRouter(StreamViewer);
