import React from 'react';

import { debounce } from 'lodash';
import Measure from 'react-measure';

/**
 * Grid with square cells that tries to fill up the parent
 * container as much as possible without overflowing.
 */
export default class ResponsiveGrid extends React.Component {
  state = {};

  render() {
    const {
      numRows = 8,
      numCols = 8,
      cellRenderer = ({ row, col }) => <div>{`${row}${col}`}</div>,
      overlayRenderer,
      gridStyles = {
        display: 'grid',
        gridTemplateColumns: `repeat(${numCols}, 1fr)`,
        gridTemplateRows: `repeat(${numRows}, 1fr)`,
      },
    } = this.props;

    const childStyle = {
      position: 'absolute',
      width: this.state.gridWidth,
      height: this.state.gridHeight,
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      ...gridStyles,
    };

    return (
      <Measure bounds onResize={this.debouncedOnResize}>
        {({ measureRef }) => (
          <div
            ref={measureRef}
            style={{ width: '100%', height: '100%', position: 'relative' }}
          >
            {overlayRenderer && (
              <div style={{ zIndex: 100, ...childStyle }}>
                {overlayRenderer()}
              </div>
            )}
            {this.state.gridWidth ? (
              <div style={childStyle}>
                {[...Array(numRows)].map((_, row) =>
                  [...Array(numCols)].map((_, col) =>
                    cellRenderer({ row, col })
                  )
                )}
              </div>
            ) : null}
          </div>
        )}
      </Measure>
    );
  }

  debouncedOnResize = debounce(({ bounds }) => {
    const boardCellWidth = bounds.width / this.props.numCols;
    const boardCellHeight = bounds.height / this.props.numRows;

    const standardEdgeLength = Math.min(boardCellWidth, boardCellHeight);

    this.setState({
      gridWidth: `${standardEdgeLength * this.props.numCols}px`,
      gridHeight: `${standardEdgeLength * this.props.numRows}px`,
    });
  }, 30);
}
