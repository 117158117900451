import './styles.scss';

import React from 'react';

import cx from 'classnames';
import { withRouter, Link } from 'react-router-dom';

import PageWidthContainer from 'components/PageWidthContainer';

class Navbar extends React.Component {
  state = { initialNavHeight: 0 };

  componentDidMount() {
    this.setState({ initialNavHeight: this.ref.current.offsetHeight });
  }

  render() {
    const { history } = this.props;
    return (
      <React.Fragment>
        <div style={{ height: `${this.state.initialNavHeight}px` }} />
        <PageWidthContainer className="Navbar">
          <div ref={this.ref} className="Navbar__inner">
            <NavbarItem
              className="Navbar__brand"
              path="/"
              text="Ollie O'Donnell"
            />
            <div className="Navbar__links">
              <NavbarItem
                className="Navbar__link"
                activePath={history.location.pathname}
                path="/"
                text="Posts"
              />
              <NavbarItem
                className="Navbar__link"
                activePath={history.location.pathname}
                path="/about"
                text="About"
              />
            </div>
          </div>
        </PageWidthContainer>
      </React.Fragment>
    );
  }

  ref = React.createRef();
}

const NavbarItem = ({ className, activePath, path, text }) => (
  <Link
    className={cx('NavbarItem', className, {
      'NavbarItem--active': activePath === path,
    })}
    to={path}
  >
    {text}
  </Link>
);

export default withRouter(Navbar);
