import React, { useState } from 'react';

const CHARACTER_SETS = [
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  'abcdefghijklmnopqrstuvwxyz',
  '0123456789',
  '.',
  ',',
  '!@#$%^&*()_+~`|}{[]:;?><,./-=',
];

const PASSWORD_LENGTH = 20;

const generatePassword = (characters = '') => {
  if (characters.length === 0) {
    return 'No characters to include.';
  }

  let password = '';
  for (let i = 0; i < PASSWORD_LENGTH; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }
  return password;
};

const PasswordGenerator = () => {
  const [toggledCharacterSets, setToggledCharacterSets] = useState(
    Object.fromEntries(CHARACTER_SETS.map(str => [str, true]))
  );

  return (
    <>
      <pre>
        {generatePassword(
          CHARACTER_SETS.filter(str => toggledCharacterSets[str]).join('')
        )}
      </pre>
      <h1>Characters to include</h1>
      {Object.entries(toggledCharacterSets).map(
        ([characters, shouldInclude]) => (
          <label
            key={`${characters}`}
            style={{ display: 'block', width: '100%' }}
          >
            <input
              type="checkbox"
              checked={shouldInclude}
              onChange={() =>
                setToggledCharacterSets(prevState => ({
                  ...prevState,
                  [characters]: !shouldInclude,
                }))
              }
            />
            <code>{characters}</code>
          </label>
        )
      )}
    </>
  );
};
export default PasswordGenerator;
