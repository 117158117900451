import { WIN, DRAW } from '../constants';
import {
  applyMove,
  isTerminal,
  getLegalMoves,
  getNumCoinsInCol,
  getGameResult,
  getCell,
  AXES,
  getLastPlayer,
} from '../gameLogic';
import { countAxis } from '../countCells';

export const WIN_VALUE = 1000000000000000;
const DRAW_VALUE = 0;
export const ALPHA = -WIN_VALUE;
export const BETA = WIN_VALUE;
const PRIMITIVE_VALUE_MAP = {
  [WIN]: WIN_VALUE,
  [DRAW]: DRAW_VALUE,
};

const getHeuristicValue = position => {
  let count = 0;
  const player = getLastPlayer(position);
  let row;
  for (let col = 0; col < 7; col++) {
    row = getNumCoinsInCol(position, col);
    for (const axis of AXES) {
      if (
        !getCell(position, row, col) &&
        countAxis(position, player, { row, col }, axis) >= 2
      ) {
        count++;
        break;
      }
    }
  }
  return count * 600;
};

const getPrimitiveValue = position =>
  PRIMITIVE_VALUE_MAP[getGameResult(position)];

const bestChildValue = (position, depth, a, b, isFinalSearch) => {
  let value = Number.NEGATIVE_INFINITY;
  for (const move of getLegalMoves(position)) {
    value =
      Math.max(
        value,
        -getMoveValue(position, move, depth - 1, -b, -a, isFinalSearch)
      ) - 5;
    a = Math.max(a, value);

    if (a >= b) {
      break;
    }
  }

  return value;
};

/* Using Negamax with alpha/beta pruning */
const getPositionValue = (position, depth, a, b, isFinalSearch) => {
  if (isTerminal(position)) {
    return -getPrimitiveValue(position);
  }

  if (depth === 0) {
    return isFinalSearch ? -getHeuristicValue(position) : 0;
  }

  return bestChildValue(position, depth, a, b, isFinalSearch);
};

export const getMoveValue = (position, move, depth, a, b, isFinalSearch) => {
  const undoMove = applyMove(position, move);
  const value = getPositionValue(position, depth, a, b, isFinalSearch);
  undoMove();
  return value;
};

// Attempt to unblock the UI
const setTimeoutPromise = func =>
  new Promise(resolve => setTimeout(() => resolve(func())));

const chooseMove = async (position, maxDepth = 3) => {
  let value, maxMove, maxValue;

  for (let depth = 0; depth <= maxDepth; depth++) {
    maxValue = Number.NEGATIVE_INFINITY;
    const isFinalSearch = depth === maxDepth;

    for (const move of getLegalMoves(position)) {
      value = await setTimeoutPromise(
        () => -getMoveValue(position, move, depth, ALPHA, BETA, isFinalSearch)
      );

      if (value >= WIN_VALUE * 0.9) {
        return move;
      }

      if (isFinalSearch && value > maxValue) {
        maxMove = move;
        maxValue = value;
      }
    }
  }

  return maxMove;
};

export default chooseMove;
