import React from 'react';

import qs from 'query-string';

const Calendar = () => (
  <>
    <p>
      Just below this paragraph is an embedded view of my personal Google
      Calendar. As of October 2021, I live in Seattle so my local time zone is
      PST, but this calendar should display in your local time zone. You can{' '}
      <a href={getUrl()}>click here to view the calendar in full screen</a>.
    </p>

    <br />

    <iframe
      title="Ollie O'Donnell's Google Calendar"
      src={getUrl()}
      width="100%"
      height="600px"
      frameBorder="0"
    />
  </>
);

const getUrl = () =>
  'https://calendar.google.com/calendar/embed?' +
  qs.stringify({
    title: 'Ollie ODonnell Calendar // ollie-o@ollie-o.com',
    ctz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    mode: 'WEEK',
    showCalendars: 0,
    showPrint: 0,
    src: [
      'b2xpdmVyb2RhYUBnbWFpbC5jb20',
      'YjdiajE5ZnUyZ3Mwbjlvc2RvNmNkM28xdmNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ',
      'ZTNhMWNobzlkbXNmNzVjNnJncmo4ZWRudDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ',
    ],
  });

export default Calendar;
