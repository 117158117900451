import './styles.scss';

import React from 'react';

import TitleArea from './components/TitleArea';
import PageContent from './components/PageContent';
import Navbar from './components/Navbar';

const PageContainer = ({ title, dateCreated, hideTitleBar, children }) => (
  <div className="PageContainer">
    <Navbar />
    {!hideTitleBar && <TitleArea title={title} dateCreated={dateCreated} />}
    <PageContent>{children}</PageContent>
  </div>
);

export default PageContainer;
