import './styles.css';

import React from 'react';

import CircularImage from './components/CircularImage';
import AboutBlurb from './components/AboutBlurb';
import AboutIcons from './components/AboutIcons';

import portrait from './images/portrait.jpg';

const About = () => (
  <div className="About">
    <CircularImage className="About__portrait" src={portrait} />
    <AboutBlurb />
    <AboutIcons />
  </div>
);

export default About;
