import { useState, useEffect } from 'react';

const getMs = (date) => Date.now() - new Date(date);

const LiveDateDifference = ({ date, children }) => {
  const [ms, setMs] = useState(getMs(date));

  useEffect(() => {
    const interval = setInterval(() => setMs(getMs(date)), 100);
    return () => clearInterval(interval);
  }, [date]);

  return children(ms);
};

export default LiveDateDifference;
