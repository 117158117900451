import React from 'react';

import { Route } from 'react-router-dom';

import pages from './pages';
import PageContainer from 'components/PageContainer';

const generateRoutesFromPages = () =>
  pages.map(({ URI, component: Page, title, dateCreated, hideTitleBar }) => (
    <Route
      key={URI}
      exact
      path={`/${URI}`}
      render={() => (
        <PageContainer
          title={title}
          dateCreated={dateCreated}
          hideTitleBar={hideTitleBar}
        >
          <Page />
        </PageContainer>
      )}
    />
  ));

export default generateRoutesFromPages;
