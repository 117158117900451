import clonedeep from 'lodash.clonedeep';

import { P1, P2, WIN, DRAW, UNFINISHED } from '../constants';
import { countAxis } from '../countCells';

const calculateGameResult = (position, lastMove) => {
  const { numCoins } = position.metadata;

  if (numCoins < 7) {
    return UNFINISHED;
  }

  if (isWin(position, lastMove)) {
    return WIN;
  }

  if (numCoins === 42) {
    return DRAW;
  }

  return UNFINISHED;
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ EXPORTS ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

export const AXES = [[1, 0], [0, 1], [1, 1], [1, -1]];

export const isWin = (position, lastMove) => {
  for (const axis of AXES) {
    if (countAxis(position, getLastPlayer(position), lastMove, axis) >= 3) {
      return WIN;
    }
  }
  return false;
};

export const getNumCoinsInCol = (position, col) => {
  let num = 0;
  while (position.cells[stringifyCoords(num, col)]) {
    num++;
  }
  return num;
};

export const stringifyCoords = (row, col) => `${row},${col}`;

export const getCell = (position, row, col) =>
  position.cells[stringifyCoords(row, col)];

export const getNextPlayer = position =>
  position.metadata.numCoins % 2 === 0 ? P1 : P2;

export const getLastPlayer = position =>
  position.metadata.numCoins % 2 === 0 ? P2 : P1;

export const isTerminal = position =>
  position.metadata.gameResult !== UNFINISHED;

export const getGameResult = position => position.metadata.gameResult;

export const isLegalMove = (position, col) => !getCell(position, 5, col);

export const getLegalMoves = position =>
  [3, 2, 4, 1, 5, 0, 6].filter(col => isLegalMove(position, col));

export const getDefaultPosition = () =>
  clonedeep({
    cells: {},
    metadata: { gameResult: UNFINISHED, numCoins: 0 },
  });

export const printPosition = position => {
  let str = '';
  for (let row = 5; row >= 0; row--) {
    for (let col = 0; col < 7; col++) {
      str += getCell(position, row, col) || '-';
      str += ' ';
    }
    str += '\n';
  }
  console.log(str);
};

const undoMove = (position, cellKey) => {
  position.cells[cellKey] = undefined;
  position.metadata.numCoins--;
  position.metadata.gameResult = UNFINISHED;
};

export const applyMove = (position, col) => {
  const row = getNumCoinsInCol(position, col);
  const cellKey = stringifyCoords(row, col);

  position.cells[cellKey] = getNextPlayer(position);

  position.metadata.numCoins++;
  position.metadata.gameResult = calculateGameResult(position, { row, col });

  return () => undoMove(position, cellKey);
};

export const applyMoveImmutable = (position, col) => {
  const newPosition = clonedeep(position);
  applyMove(newPosition, col);
  return newPosition;
};
